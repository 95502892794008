// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #366f8a;
  --ion-color-primary-rgb: 54,111,138;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #306279;
  --ion-color-primary-tint: #4a7d96;

  --ion-color-secondary: #66ad4e;
  --ion-color-secondary-rgb: 102,173,78;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #5a9845;
  --ion-color-secondary-tint: #75b560;

  --ion-color-tertiary: #f9b7cf;
  --ion-color-tertiary-rgb: 249,183,207;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #dba1b6;
  --ion-color-tertiary-tint: #fabed4;

  --ion-color-success: #84BD71;
  --ion-color-success-rgb: 132,189,113;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #74a663;
  --ion-color-success-tint: #90c47f;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #CF153B;
  --ion-color-danger-rgb: 207,21,59;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #b61234;
  --ion-color-danger-tint: #d42c4f;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-gold: #FFD700;
  --ion-color-gold-rgb: 255,215,0;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0,0,0;
  --ion-color-gold-shade: #e0bd00;
  --ion-color-gold-tint: #ffdb1a; 

  --ion-color-deer: #505050;
  --ion-color-deer-rgb: 80,80,80;
  --ion-color-deer-contrast: #ffffff;
  --ion-color-deer-contrast-rgb: 255,255,255;
  --ion-color-deer-shade: #464646;
  --ion-color-deer-tint: #626262;

  --ion-color-turkey: #845356;
  --ion-color-turkey-rgb: 132,83,86;
  --ion-color-turkey-contrast: #ffffff;
  --ion-color-turkey-contrast-rgb: 255,255,255;
  --ion-color-turkey-shade: #74494c;
  --ion-color-turkey-tint: #906467;

  --ion-color-waterfowl: #1D5D3B;
  --ion-color-waterfowl-rgb: 29,93,59;
  --ion-color-waterfowl-contrast: #ffffff;
  --ion-color-waterfowl-contrast-rgb: 255,255,255;
  --ion-color-waterfowl-shade: #1a5234;
  --ion-color-waterfowl-tint: #346d4f;

  --ion-color-upland: #A39D47;
  --ion-color-upland-rgb: 163,157,71;
  --ion-color-upland-contrast: #000000;
  --ion-color-upland-contrast-rgb: 0,0,0;
  --ion-color-upland-shade: #8f8a3e;
  --ion-color-upland-tint: #aca759;

  --ion-color-varmint: #352820;
  --ion-color-varmint-rgb: 53,40,32;
  --ion-color-varmint-contrast: #ffffff;
  --ion-color-varmint-contrast-rgb: 255,255,255;
  --ion-color-varmint-shade: #2f231c;
  --ion-color-varmint-tint: #493e36;

  --ion-color-fishing: #3A567E;
  --ion-color-fishing-rgb: 58,86,126;
  --ion-color-fishing-contrast: #ffffff;
  --ion-color-fishing-contrast-rgb: 255,255,255;
  --ion-color-fishing-shade: #334c6f;
  --ion-color-fishing-tint: #4e678b;

  --ion-color-mushroom: #c7b299;
	--ion-color-mushroom-rgb: 199,178,153;
	--ion-color-mushroom-contrast: #000000;
	--ion-color-mushroom-contrast-rgb: 0,0,0;
	--ion-color-mushroom-shade: #af9d87;
	--ion-color-mushroom-tint: #cdbaa3;
}
