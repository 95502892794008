/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~viewerjs/dist/viewer.css';

// custom colors 
.ion-color-gold {
  --ion-color-base: var(--ion-color-gold) !important;
  --ion-color-base-rgb: var(--ion-color-gold-rgb) !important;
  --ion-color-contrast: var(--ion-color-gold-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-gold-shade) !important;
  --ion-color-tint: var(--ion-color-gold-tint) !important;
  }

.ion-color-deer {
  --ion-color-base: var(--ion-color-deer) !important;
  --ion-color-base-rgb: var(--ion-color-deer-rgb) !important;
  --ion-color-contrast: var(--ion-color-deer-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-deer-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-deer-shade) !important;
  --ion-color-tint: var(--ion-color-deer-tint) !important;
  }

.ion-color-turkey {
--ion-color-base: var(--ion-color-turkey) !important;
--ion-color-base-rgb: var(--ion-color-turkey-rgb) !important;
--ion-color-contrast: var(--ion-color-turkey-contrast) !important;
--ion-color-contrast-rgb: var(--ion-color-turkey-contrast-rgb) !important;
--ion-color-shade: var(--ion-color-turkey-shade) !important;
--ion-color-tint: var(--ion-color-turkey-tint) !important;
} 

.ion-color-upland {
--ion-color-base: var(--ion-color-upland) !important;
--ion-color-base-rgb: var(--ion-color-upland-rgb) !important;
--ion-color-contrast: var(--ion-color-upland-contrast) !important;
--ion-color-contrast-rgb: var(--ion-color-upland-contrast-rgb) !important;
--ion-color-shade: var(--ion-color-upland-shade) !important;
--ion-color-tint: var(--ion-color-upland-tint) !important;
}

.ion-color-waterfowl {
--ion-color-base: var(--ion-color-waterfowl) !important;
--ion-color-base-rgb: var(--ion-color-waterfowl-rgb) !important;
--ion-color-contrast: var(--ion-color-waterfowl-contrast) !important;
--ion-color-contrast-rgb: var(--ion-color-waterfowl-contrast-rgb) !important;
--ion-color-shade: var(--ion-color-waterfowl-shade) !important;
--ion-color-tint: var(--ion-color-waterfowl-tint) !important;
}

.ion-color-varmint {
--ion-color-base: var(--ion-color-varmint) !important;
--ion-color-base-rgb: var(--ion-color-varmint-rgb) !important;
--ion-color-contrast: var(--ion-color-varmint-contrast) !important;
--ion-color-contrast-rgb: var(--ion-color-varmint-contrast-rgb) !important;
--ion-color-shade: var(--ion-color-varmint-shade) !important;
--ion-color-tint: var(--ion-color-varmint-tint) !important;
}

.ion-color-fishing {
  --ion-color-base: var(--ion-color-fishing) !important;
  --ion-color-base-rgb: var(--ion-color-fishing-rgb) !important;
  --ion-color-contrast: var(--ion-color-fishing-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-fishing-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-fishing-shade) !important;
  --ion-color-tint: var(--ion-color-fishing-tint) !important;
  }

  .ion-color-mushroom {
    --ion-color-base: var(--ion-color-mushroom) !important;
    --ion-color-base-rgb: var(--ion-color-mushroom-rgb) !important;
    --ion-color-contrast: var(--ion-color-mushroom-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-mushroom-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-mushroom-shade) !important;
    --ion-color-tint: var(--ion-color-mushroom-tint) !important;
    }



// http://ionicframework.com/docs/v2/theming/


// App Global Sass
// --------------------------------------------------
// Put style rules here that you want to apply globally. These
// styles are for the entire app and not just one component.
// Additionally, this file can be also used as an entry point
// to import other Sass files to be included in the output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's
// default Sass variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule
// for the .md, .ios, or .wp mode classes. The mode class is
// automatically applied to the <body> element in the app.
  
  body{
    padding-top: constant(safe-area-inset-top);//for iOS 11.2
    padding-top: env(safe-area-inset-top);//for iOS 11.1
  }

  .validation-errors{
    padding-left: 15px;
}
  
  .click:hover{
    cursor: pointer; 
  }

  .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 5px;
  }

  ul {
    text-align: left;
  }
  
  .profile-image img {
    max-width: 30px;
    height: 32px;
    border-radius: 50%;
    margin-top: 3px;
  }
  
  .profile-image{
      float: right;
      margin-top: 4px;
      margin-left: 5px;
      background-color: #488aff;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  
  .no-scroll .scroll-content{
           overflow: hidden;
      }
  
  .content-md{
    // margin-bottom: 56px;
  }    
  
  .list-header{
    font-size: 120%; 
  }
  
  .noMargin{
    margin-bottom: 0%;
  }

  .infoCard{
    max-width: 300px;
  }

  .addNewCard{
    max-width: 300px;
    min-width: 250px;
    min-height: 300px;
    max-height: 400px;
    text-align: center;
  }

  .addNewCard img{
    max-height: 225px;
  }
  
  
  
  .content-ios{
    // margin-bottom: 44px;
  }
  
  .scroll-content{
    color: rgb(0, 0, 0);
    font-size: 120%
    // margin-bottom: 50px;
  }  
  
  .navbar{
    display: inline; 
  }
  
  .rightButtons{
    float: right;
  }
  
  .indent{
    padding-left: 8%;
  }
  
  .alert-radio-label {
    white-space: normal!important;
  }

  ion-content {
    --background:none;
    background: url("assets/img/backgroundBright.svg") 0 0/100% 197% no-repeat;
  }

  .noBackground {
    --background: none;
    background: none;
  }


  ion-avatar img {
    object-fit: contain;
  }

  ion-thumbnail img {
    object-fit: contain;
  }

  ion-avatar cl-image img{
    border-radius: 50%;
  }

  :root{
    ion-list-header {
      font: 1.2em sans-serif;
    }    
  }

  
      
  